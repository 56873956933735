import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import BlogList from '../components/blog-list'
import SiteMeta from '../components/meta-data'


class BlogTagsTemplate extends React.Component{

    constructor(props) {
        super(props)
    }

    render(){
        const posts = get(this, 'props.data.allContentfulBlogPost.edges')
        const metaData = get(this.props, 'data.site.siteMetadata.blogPage')
        const tag = this.props.pageContext.tag
        const tags  = Array.from(new Set(posts.reduce((acc, post) => acc.concat(post.node.tags),[])))
        const data = { hero:'hero-blog', size:'is-medium', isHidden: true, text: 'Blog' }
        return(
          <Layout location={this.props.location} data={data}>
              <SiteMeta metaData={metaData} tag={tag}/>
              <BlogList posts={posts} tags={tags} />
          </Layout>
        )
    }
}

export default BlogTagsTemplate

export const pageQuery = graphql`
  query BlogIndexByTag($tag: String!) {
    site {
      siteMetadata {
        blogPage {
          title
          description
          imageUrl
          siteUrl
        }
      }
    }  
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC, } 
                          filter: {node_locale: {eq: "en-GB"} 
                          tags: {eq: $tag}}
                          ) {
        edges {
          node {
            title
            publishDate(formatString: "Do MMMM, YYYY")
            tags
            slug
            heroImage {
              file {
                url
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
    }
  }
`