import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import TagList from '../components/tag-list'

class BlogList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			visible:9,
		}

		this.loadMore = this.loadMore.bind(this)
	}

	loadMore(){
		this.setState((prevState) => {
			return {
				visible:prevState.visible + 3
			}
		})
	}

	render() {

		const { posts, tags} = this.props

		return (
			<div className="blog section">
				<div className="container">
					<TagList/>
					<div className="columns is-multiline">
						{
							posts.slice(0, this.state.visible).map(({node}, index) => {
								return(
									<div className="column is-one-third" key={index}>
										<div className="card">
											<div className="card-image">
												<figure className="image is-3by2">
													<Link to={`/blog/${node.slug}/`}>
														<img src={node.heroImage.file.url} alt={`${node.title} hero image`}/>
													</Link>
												</figure>
											</div>
											<div className="card-content">
												<h1 className="title is-size-4">{node.title}</h1>
												<p>
													<Link to={`/blog/${node.slug}/`}>Read blog</Link>
												</p>
											</div>
										</div>		
									</div>
								)
							})
						}
					</div> 
					{ this.state.visible < posts.length &&
						<div className="columns is-centered">
							<div className="column is-6">
								<div className="has-text-centered">
									<button className="button is-danger is-medium" type="button" onClick={this.loadMore}>Load More</button>
								</div>
							</div>	
						</div>
					}
				</div>
			</div>
		)
	}

}

export default BlogList