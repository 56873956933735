import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'


export default () => (
    <StaticQuery
        query={graphql `
            query TagQuery {
                allContentfulBlogPost {
                    edges {
                      node {
                        tags
                      }
                    }
                } 
            }
        
        `}

        render = { data => {
            const posts = data.allContentfulBlogPost.edges
            const tags =  Array.from(new Set(posts.reduce((acc, post) => acc.concat(post.node.tags),[])))
            return (
                <div className="tags are-medium is-capitalized">
                    <span className="tag">
                        <Link to='/blog'>all</Link>
                    </span>
                    {
                        tags.sort().map((tag,index) => {
                            return(
                                <span className="tag" key={index}>
                                    <Link to={`/blog/tags/${tag}/`}>{tag}</Link>
                                </span>
                            )
                        })
                    }
                </div>
            )
        }}
    />    
)
